angular.module('GCR-CPSP').controller('ContactController', function($scope, $rootScope, $modal, Setting, Contact) {
    $rootScope.$on('Contact', function() {
        $modal.open({
            animation: true,
            templateUrl: '/view/contact/store/modal/contact.html',
            keyboard: true,
            backdrop: true,
            size: 'lg',
            windowClass: 'contact-window',
            controller: function($scope, $modalInstance, Setting) {
                $scope.alert = $rootScope.alert;
                $scope.countries = Setting.options.countries || {};
                $scope.industries = Setting.options.industries || {};
                $scope.topics = {
                    1: 'I want to be a certified partner',
                    2: 'Others'
                };
                $scope.company_size = {
                    1: '0~50',
                    2: '50~250',
                    3: '250~1000',
                    4: 'More than 1000'
                };
                $scope.contact = {
                    first_name: '',
                    last_name: '',
                    email: '',
                    country: $scope.countries[Object.keys($scope.countries)[0]],
                    industry: $scope.industries[0],
                    topic: Object.keys($scope.topics)[0],
                    company_name: '',
                    company_size: Object.keys($scope.topics)[0],
                    website: '',
                    address: '',
                    office_phone: '',
                    message: ''
                };
                $scope.submit = function() {
                    var contact = new Contact($scope.contact);
                    contact.$guestSave(function(result) {
                        $modalInstance.close();
                        $rootScope.$broadcast('ContactSuccess');
                    }, function(error) {
                        var result = error.data || {};
                        var messages = result.messages || [];
                        $rootScope.$broadcast('MessageUpdate', 'errors', messages);
                    });
                };
            }
        });
    });
    $rootScope.$on('ContactSuccess', function() {
        $modal.open({
            animation: true,
            templateUrl: '/view/contact/store/modal/contact_success.html',
            keyboard: true,
            backdrop: true,
            controller: function($scope, $modalInstance) {
                $scope.close = function() {
                    $modalInstance.close();
                };
            }
        });
    });
});