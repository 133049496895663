'use strict';
//<ng-include src="'/view/maintenance/store.html'"></ng-include>
angular.module('GCR-CPSP').controller('MaintenanceController', function($scope, $rootScope, $routeParams, $modal, $timeout, Setting) {
    $scope.user = Setting.user;
    $scope.maintenance20160716 = function() {
        if (!$rootScope.maintenance20160716modal) {
            $modal.open({
                animation: true,
                templateUrl: '/view/maintenance/modal/2016-07-16.html',
                size: 'lg',
                keyboard: true,
                backdrop: true,
                controller: function($scope, $modalInstance) {
                    $scope.ok = function() {
                        $rootScope.maintenance20160716modal = true;
                        $modalInstance.close();
                    };
                }
            });
        }
    };
    $scope.maintenance20161023 = function() {
        var sdate=new Date('2016/10/23 05:00');
        var edate=new Date('2016/10/23 05:30');
        var checkdate = new Date();
        //if (!$rootScope.maintenance20161023modal) {
        if ($scope.user && (checkdate >=sdate &&checkdate <=edate)) {
            $modal.open({
                animation: true,
                templateUrl: '/view/maintenance/modal/2016-10-23.html',
                size: 'lg',
                keyboard: true,
                backdrop: true,
                controller: function($scope, $modalInstance) {
                    $scope.ok = function() {
                        //$rootScope.maintenance20161023modal = true;
                        $modalInstance.close();
                    };
                }
            });
        }
        //}
    };
    $scope.maintenance20170108 = function() {
        //var sdate=new Date('2016/10/23 05:00');
        var edate=new Date('2017/01/08 07:00');
        var checkdate = new Date();
        //if (!$rootScope.maintenance20161023modal) {
        if ($scope.user && checkdate < edate) {
            $modal.open({
                animation: true,
                templateUrl: '/view/maintenance/modal/2017-01-08.html',
                size: 'lg',
                keyboard: true,
                backdrop: true,
                controller: function($scope, $modalInstance) {
                    $scope.ok = function() {
                        //$rootScope.maintenance20161023modal = true;
                        $modalInstance.close();
                    };
                }
            });
        }
        //}
    };

    $scope.maintenance20170502 = function() {
        var edate=new Date('2017/05/04 23:59');
        var checkdate = new Date();
        if ($scope.user && checkdate < edate) {
            $modal.open({
                animation: true,
                templateUrl: '/view/maintenance/modal/2017-05-02.html',
                size: 'lg',
                keyboard: true,
                backdrop: true,
                controller: function($scope, $modalInstance) {
                    $scope.ok = function() {
                        $modalInstance.close();
                    };
                }
            });
        }
    };
});
